<template>
  <div class="box" id="captcha">
    <div class="content">
      <van-image
        width="100%"
        fit="fill"
        height="100%"
        :src="require('@/assets/bgimg.png')"
      />
      <div class="form-view">
        <van-form @submit="onSubmit">
          <div class="tit">一键完成任务脚本</div>
          <div class="itemtitle">账号</div>
          <van-field
            v-model="tel"
            name="tel"
            clearable
            placeholder="请填写账号"
            style="font-size: 1.05em;"
            label=""
          />
          <div class="itemtitle">密码</div>
          <van-field
            v-model="password"
            type="password"
            name="password"
            placeholder="请输入密码"
            style="font-size: 1.05em;"
            label=""
          />
          <div style="margin: 40px 10px; margin-bottom: 10px;">
            <van-button round block type="danger" color="linear-gradient(to right, #00acec, #00acec)" size="large" native-type="submit" :disabled="!canRegust">
              登录
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Form, Field, Button, Toast, Dialog, Image as VanImage, Popup, Area } from 'vant'
import SlideVerify from 'vue-monoplasty-slide-verify'
import { Base64 } from 'js-base64'
import { areaList } from '@vant/area-data'
// import AESUtils from '../../utils/aes.js'
import axios from 'axios'
Vue.use(Base64)
Vue.use(SlideVerify)
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(Toast)
Vue.use(Dialog)
Vue.use(VanImage)
Vue.use(Popup)
Vue.use(Area)
export default {
  mounted () {
  },
  created () {
    // var ua = navigator.userAgent.toLowerCase()
    // var isWeixin = ua.indexOf('micromessenger') !== -1
    // if (!isWeixin) {
    //   console.log('liulanqi')
    //   this.show = false
    // } else {
    //   console.log('weixin')
    //   this.show = true
    // }
    // this.xzdz()
  },
  data () {
    return {
      lang: false,
      canRegust: true,
      tel: '',
      dztext: '110101',
      add: '',
      password: '',
      xxdz: '',
      areaList: areaList,
      password1: '',
      code: '', // 用户输入的验证码
      address: '', // 后端传递过来的验证码
      msg: '发送验证码',
      timer: null,
      time: 120,
      showArea: false,
      recomandid: '',
      showPopup: false,
      province: '',
      city: '',
      region: '',
      // msg: "",
      text: '向右滑',
      // 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
      accuracy: 3,
      show: false,
      downSrc: ''
      // slideImgs: [''] // 可自定义拼图图片数组，未传则使用插件内默认的图片数组
    }
  },
  methods: {
    editChange (event) {
      console.log('e', event)
    },
    downLoadApp () {
      window.location = `${this.downSrc}`
    },
    bindqd (event) {
      let st = ''
      this.province = event[0].name
      this.city = event[1].name
      this.region = event[2].name
      event.forEach((item, index) => {
        st = st + item.name
      })
      this.add = st
      this.showPopup = false
    },
    zsPopup () {
      this.showPopup = true
    },
    close () {
      this.showPopup = false
    },
    handleClick () {
      // 父组件直接可以调用刷新方法
      this.$refs.slideblock.reset()
    },
    // 表单提交
    // onSubmit (content) {
    //   var sendData = {
    //     userName: content.tel,
    //     password: content.password,
    //     messageCode: content.code,
    //     code: this.$route.params.recomandid
    //   }
    //   console.log(sendData)
    //   // 注意数据类型  content.code - string   admincode-number
    //   if (content.tel.length === 0) {
    //     Toast('请输入手机号')
    //   } else if (content.code.length === 0) {
    //     Toast('请输入验证码')
    //   } else if (content.password.length < 6) {
    //     Toast('密码请设置六位以上')
    //   } else {
    //     this.canRegust = false
    //     register(sendData).then((res) => {
    //       if (res.data.code * 1 === 100) {
    //         console.log('======注册请求开始======')
    //         console.log(res)
    //         console.log('======注册请求结束======')
    //         Dialog.alert({
    //           confirmButtonColor: '#fb7c62',
    //           message: res.data.msg,
    //           theme: 'round-button'
    //         }).then(() => {
    //           this.canRegust = true
    //         })
    //       } else {
    //         this.canRegust = true
    //         Toast(res.data.msg)
    //       }
    //     })
    //   }
    // },
    onSubmit (content) {
      // axios(options)
      var sendData = {
        userName: content.tel,
        password: content.password,
        loginType: 'admin'
      }
      const options = {
        method: 'POST',
        data: sendData,
        // data: AESUtils.encrypt(JSON.stringify(sendData)),
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        url: 'http://47.104.18.179:8085/common/login1568471'
        // url: 'http://47.104.18.179:8082/common/login1568471'
      }
      if (content.tel.length === 0) {
        if (this.lang) {
          Toast('Fill in phone')
        } else {
          Toast('请输入帐号')
        }
        // Toast('请输入手机号')
      } else if (content.password.length < 6) {
        if (this.lang) {
          Toast('Fill in password')
        } else {
          Toast('请输入密码')
        }
      } else {
        this.canRegust = false
        axios(options).then((res) => {
          if (res.data.code * 1 === 100) {
            // Dialog.alert({
            //   confirmButtonColor: '#fb7c62',
            //   message: res.data.msg,
            //   theme: 'round-button'
            // }).then(() => {
            //   this.canRegust = true
            // })
            localStorage.setItem('token', res.data.data)
            localStorage.setItem('user', content.tel)
            this.$router.push('/index')
            this.canRegust = true
          } else {
            this.canRegust = true
            if (this.lang) {
              if (res.code === '500') {
                Toast('Error Retry')
              } else {
                Toast('Error Retry')
              }
            } else {
              Toast('错误')
            }
          }
        })
      }
    }
  },
  computed: {
    telflag () {
      if (this.tel.length !== 11 || this.msg !== '发送验证码') {
        return true
      } else {
        return false
      }
    },
    flag () {
      // 可以使用 正则表达式 ，目前采用的字符串的长度代替了 正则表达式
      if (
        this.tel.length === 11 &&
        this.code !== '' &&
        this.password.length > 5
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
::v-deep  .van-button--large{
  height 50px
}
.tit
  font-size 20px
  font-weight bold
  padding 10px
.van-cell
  background-color rgba(255,255,0,0.0);
.form-view
  display flex
  background-color rgba(255,255,255,0.8);
  flex-direction column
  width: 80%
  max-width 400px
  margin 0
  padding 30px 10px
  position absolute
  border-radius 20px
  border 1px solid #fff
  top 8.5rem
  .itemtitle
    padding 16px 0px 0px 16px
.xiazaiview
  display: flex
  flex-direction: row
  justify-content: space-around
  margin-bottom 20px
  .download
    width: 49%
.popup-slide-box
  background #ffffff
  width 315px
  height 400px
  // border-radius 24px
  text-align center
  .popup-title
    font-size 32px
    color #333333
    height 108px
    line-height 108px
    border-bottom 1px solid #efefef
    font-weight bold
  .popup-content
    width 100%

</style>
