// import request from './../utils/request'
import request from '@/utils/request' // @代表的就是src

/**
 * 发送短信
 */
export function senCode (params) {
  return request.post('common/sendForRegister', params)
}

/**
 * 发送短信验证码接口
 */
export function register (params) {
  return request.post('register', params)
}

export function downurl (data) {
  return request.post('common/getNewVersion', data)
}

export function selectBindingUser (data) {
  return request.post('admin/vip/selectBindingUser', data)
}

export function saveBinding (data) {
  return request.post('admin/vip/saveBinding', data)
}

export function yjwc (data) {
  return request.post('admin/vip/yjwc', data)
}
export function getVipUserList (data) {
  return request.post('admin/user/getVipUserList', data)
}
export function insertVipUser (data) {
  return request.post('admin/user/insertVipUser', data)
}
export function cancelBinding (data) {
  return request.post('admin/vip/cancelBinding', data)
}
export function yjhz (data) {
  return request.post('admin/vip/yjhz', data)
}
export function yjft (data) {
  return request.post('admin/vip/yjft', data)
}
export function getTaskInfos (data) {
  return request.post('admin/vip/getTaskInfos', data)
}

export function saveBindings (data) {
  return request.post('admin/vip/saveBindings', data)
}
export function editPassword (data) {
  return request.post('admin/vip/editPassword', data)
}
export function plregister (data) {
  return request.post('common/register', data)
}

export function register2 (data) {
  return request.post('common/register2', data)
}

export function saveBindingsByPhone (data) {
  return request.post('admin/vip/saveBindingsByPhone', data)
}
