<template>
  <div class="box">
    <div class="content">
      <div class="top">
      <div class="content-top">
        <div style="margin-bottom: 20px;">添加vip脚本帐号</div>
        <div style="margin-bottom: 10px; font-size: 18px; text-align: center;">当前登录账号：{{ user }}</div>
        <van-cell-group>
  <van-field
  v-model="userName"
    label="上级手机号"
    icon="question-o"
    rows="10"
    placeholder="请输入帐号"
  />
</van-cell-group>
<div style="display: flex; align-items: center; justify-content: center;margin-top: 50px;">
  <van-button type="primary" size="small" @click="saveBinding" style="width: 160px;">添加</van-button>
</div>

      </div>
    </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Form, Field, Button, Toast, Dialog, Image as VanImage, Popup, Area, Col, Row, Cell, CellGroup, List, PullRefresh } from 'vant'
import SlideVerify from 'vue-monoplasty-slide-verify'
import { Base64 } from 'js-base64'
import { areaList } from '@vant/area-data'
// import AESUtils from '../../utils/aes.js'
// import axios from 'axios'
import { saveBindingsByPhone } from '../../api/index.js'
Vue.use(Base64)
Vue.use(Col)
Vue.use(Row)
Vue.use(SlideVerify)
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(Toast)
Vue.use(Dialog)
Vue.use(VanImage)
Vue.use(Popup)
Vue.use(Area)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(List)
Vue.use(PullRefresh)
export default {
  mounted () {
  },
  created () {
    if (!localStorage.getItem('token') || localStorage.getItem('token') === null) {
      this.$router.push('/login')
    }
    this.user = localStorage.getItem('user')
    // var ua = navigator.userAgent.toLowerCase()
    // var isWeixin = ua.indexOf('micromessenger') !== -1
    // if (!isWeixin) {
    //   console.log('liulanqi')
    //   this.show = false
    // } else {
    //   console.log('weixin')
    //   this.show = true
    // }
    // this.xzdz()
  },
  data () {
    return {
      user: '',
      lang: false,
      canRegust: true,
      tel: '',
      dztext: '110101',
      add: '',
      password: '',
      xxdz: '',
      areaList: areaList,
      password1: '',
      code: '', // 用户输入的验证码
      address: '', // 后端传递过来的验证码
      msg: '发送验证码',
      timer: null,
      time: 120,
      showArea: false,
      recomandid: '',
      showPopup: false,
      province: '',
      city: '',
      region: '',
      // msg: "",
      text: '向右滑',
      // 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
      accuracy: 3,
      show: false,
      downSrc: '',
      list: [],
      error: false,
      loading: false,
      finished: false,
      refreshing: false,
      userName: ''
      // slideImgs: [''] // 可自定义拼图图片数组，未传则使用插件内默认的图片数组
    }
  },

  methods: {
    saveBinding () {
      // console.log(this.userName.replace(/，/g, ','))
      const sendData = {
        userName: this.userName.replace(/，/g, ',').replace(/[\r\n]+/g, '').replace(/\s+/g, '')
      }
      Toast.loading({
        message: '添加中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0
      })
      saveBindingsByPhone(sendData).then((res) => {
        if (res.data.code * 1 === 100) {
          // this.$router.back(-1)
          Toast.clear()
          Toast(res.data.msg)
          this.userName = ''
        } else {
          this.canRegust = true
          Toast('错误')
        }
      })
    },
    onLoad () {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = []
          this.refreshing = false
        }

        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1)
        }
        this.loading = false

        if (this.list.length >= 40) {
          this.finished = true
        }
      }, 1000)
    },
    onRefresh () {
      // 清空列表数据
      this.finished = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    editChange (event) {
      console.log('e', event)
    },
    downLoadApp () {
      window.location = `${this.downSrc}`
    },
    bindqd (event) {
      console.log(event)
      let st = ''
      this.province = event[0].name
      this.city = event[1].name
      this.region = event[2].name
      event.forEach((item, index) => {
        st = st + item.name
      })
      console.log(st)
      this.add = st
      this.showPopup = false
    },
    zsPopup () {
      this.showPopup = true
    },
    close () {
      this.showPopup = false
    },
    handleClick () {
      // 父组件直接可以调用刷新方法
      this.$refs.slideblock.reset()
    }
  },
  computed: {
    telflag () {
      if (this.tel.length !== 11 || this.msg !== '发送验证码') {
        return true
      } else {
        return false
      }
    },
    flag () {
      // 可以使用 正则表达式 ，目前采用的字符串的长度代替了 正则表达式
      if (
        this.tel.length === 11 &&
        this.code !== '' &&
        this.password.length > 5
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.top {
  width 100%
  padding 15px
  box-sizing border-box
  display flex
  flex-direction  column
  font-size 20px
  background url('~@/assets/bgimg.png') no-repeat
  background-size cover
  padding-top 30px
  height 100%
}
.content-top
  background-color #fff
  height 100%
  width 100%
  border-radius 10px
  padding 20px 10px
  box-sizing border-box
.tipbox {
  text-align left
  width 100%
  padding 5px 15px
  box-sizing border-box
}
.btnbox{
  display flex
  justify-content space-around
  width 100%
  margin 20px 0
  align-items center
}
::v-deep  .van-button--large{
  height 50px
}
.tit
  font-size 20px
  font-weight bold
  padding 10px
.van-cell
  background-color rgba(255,255,0,0.0);
.form-view
  display flex
  flex-direction column
  width: 80%
  max-width 400px
  margin 0
  padding 20px 0
  position absolute
  top 10.5rem
  .itemtitle
    padding 16px 0px 0px 16px
.xiazaiview
  display: flex
  flex-direction: row
  justify-content: space-around
  margin-bottom 20px
  .download
    width: 49%
.popup-slide-box
  background #ffffff
  width 315px
  height 400px
  // border-radius 24px
  text-align center
  .popup-title
    font-size 32px
    color #333333
    height 108px
    line-height 108px
    border-bottom 1px solid #efefef
    font-weight bold
  .popup-content
    width 100%

</style>
