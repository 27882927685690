<template>
  <div class="box">
    <div class="content">
      <div class="top">
        <van-row style="display: flex; align-items: center; flex-direction: column; flex-wrap: wrap;justify-content: center; margin-top: 30px;">
          <div style="margin-top: 30px;"><img src="../../assets/welcome.png" alt="" srcset="" style="width: 140px;"></div>
          <div style="margin-bottom: 10px; margin-top: 10px; font-size: 18px; text-align: center;">当前登录账号：{{ user }}</div>
          <div class="itemclass" style="margin: 30px 0; margin-top: 60px;">
            <div class="itembox">
              <div class="num">
                {{alltotal}}
              </div>
              <div class="text">
                已绑定手机号
              </div>
            </div>
            <div class="itembox">
              <div class="num">
                {{ywc}}
              </div>
              <div class="text">
                已完成
              </div>
            </div>
            <div class="itembox">
              <div class="num">
                {{wwc}}
              </div>
              <div class="text">
                未完成
              </div>
            </div>
          </div>
          <div class="btnview">
            <div class="btnitem" @click="zhlist">
              <img src="../../assets/lb.png" alt="" srcset="">
              <span>账号列表</span>
            </div>
            <div class="btnitem" @click="saveBinding1">
              <img src="../../assets/add.png" alt="" srcset="">
              <span>添加账号</span>
            </div>
            <div class="btnitem" @click="hzBinding1">
              <img src="../../assets/hz.png" alt="" srcset="">
              <span>一键划转</span>
            </div>
            <div class="btnitem" @click="ftBinding">
              <img src="../../assets/ft.png" alt="" srcset="">
              <span>一键复投</span>
            </div>
            <div class="btnitem" @click="setpawtcdl">
              <img src="../../assets/setpaw.png" alt="" srcset="">
              <span>修改密码</span>
            </div>
            <div class="btnitem" @click='tcdl'>
              <img src="../../assets/out.png" alt="" srcset="">
              <span>退出</span>
            </div>
            <!-- <div class="btnitem" @click="saveBinding">
              <img src="../../assets/add.png" alt="" srcset="">
              <span>一键绑定</span>
            </div>
            <div class="btnitem" @click="hzBinding">
              <img src="../../assets/hz.png" alt="" srcset="">
              <span>一键注册</span>
            </div> -->
          </div>
            <!-- <div style="width: 100%; text-align: center;">已绑定手机号：{{alltotal}}</div>
            <div class="btnbox" @click="zhlist" style="display: flex; flex-direction: column;margin-top: 20px;">
              <div>账号列表</div>
              <div style="display: flex; align-items: center; justify-content: space-between; width: 80%; font-size: 18px;"><span style="color:aquamarine">已完成：{{ ywc }}</span><span style="color: red;">未完成：{{ wwc }}</span></div>
            </div> -->

            <!-- <van-button icon="plus" type="primary" size="small" @click="saveBinding">添加账号</van-button> -->
            <!-- <div class="btnbox" @click="saveBinding">添加账号</div> -->

            <!-- <van-button icon="plus" type="primary" size="small" @click="hzBinding">一键划转</van-button> -->
            <!-- <div class="btnbox" @click="hzBinding">一键划转</div> -->

            <!-- <van-button icon="plus" type="primary" size="small" @click="ftBinding">一键复投</van-button> -->
            <!-- <div class="btnbox" @click="ftBinding">一键复投</div> -->

            <!-- <van-button type="danger" size="small" @click='tcdl'>退出</van-button> -->
            <!-- <div class="btnbox" @click="tcdl" style="background: red; height: 45px;">退出</div>
            <div class="btnbox" @click="setpawtcdl" style="background: red; height: 45px;">修改密码</div> -->
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Form, Field, Button, Toast, Dialog, Image as VanImage, Popup, Area, Col, Row, Cell, CellGroup, List, PullRefresh } from 'vant'
import SlideVerify from 'vue-monoplasty-slide-verify'
import { Base64 } from 'js-base64'
import { areaList } from '@vant/area-data'
// import AESUtils from '../../utils/aes.js'
import { selectBindingUser, yjwc, cancelBinding } from '../../api/index.js'
// import axios from 'axios'
Vue.use(Base64)
Vue.use(Col)
Vue.use(Row)
Vue.use(SlideVerify)
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(Toast)
Vue.use(Dialog)
Vue.use(VanImage)
Vue.use(Popup)
Vue.use(Area)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(List)
Vue.use(PullRefresh)
export default {
  mounted () {
  },
  created () {
    if (!localStorage.getItem('token') || localStorage.getItem('token') === null) {
      this.$router.push('/login')
    }
    this.user = localStorage.getItem('user')
    // this.selectBindingUser()
    // var ua = navigator.userAgent.toLowerCase()
    // var isWeixin = ua.indexOf('micromessenger') !== -1
    // if (!isWeixin) {
    //   console.log('liulanqi')
    //   this.show = false
    // } else {
    //   console.log('weixin')
    //   this.show = true
    // }
    // this.xzdz()
    this.alldata()
  },
  data () {
    return {
      lang: false,
      canRegust: false,
      tel: '',
      dztext: '110101',
      add: '',
      password: '',
      xxdz: '',
      areaList: areaList,
      password1: '',
      code: '', // 用户输入的验证码
      address: '', // 后端传递过来的验证码
      msg: '发送验证码',
      timer: null,
      time: 120,
      showArea: false,
      recomandid: '',
      showPopup: false,
      province: '',
      city: '',
      region: '',
      total: 0,
      alltotal: 0,
      user: '',
      ywc: 0,
      wwc: 0,
      // msg: "",
      text: '向右滑',
      // 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
      accuracy: 3,
      show: false,
      downSrc: '',
      list: [],
      error: false,
      loading: false,
      finished: false,
      refreshing: false,
      pageNum: 0
      // slideImgs: [''] // 可自定义拼图图片数组，未传则使用插件内默认的图片数组
    }
  },

  methods: {
    alldata () {
      const that = this
      var sendData = {
        pageSize: 10,
        pageNum: 1,
        taskStatus: null
      }
      selectBindingUser(sendData).then((res) => {
        if (res.data.code * 1 === 100) {
          that.alltotal = res.data.total
        }
      })
      selectBindingUser({
        pageSize: 10,
        pageNum: 1,
        taskStatus: '1201'
      }).then((res) => {
        if (res.data.code * 1 === 100) {
          that.wwc = res.data.total
        }
      })
      selectBindingUser({
        pageSize: 10,
        pageNum: 1,
        taskStatus: '1202'
      }).then((res) => {
        if (res.data.code * 1 === 100) {
          that.ywc = res.data.total
        }
      })
    },
    zhlist () {
      this.$router.push('/list')
    },
    unbind (userId, userPhone) {
      Dialog.confirm({
        title: '提示',
        message: `确定要解绑${userPhone}吗？`
      })
        .then(() => {
          // on confirm
          cancelBinding({ userId: userId }).then((res) => {
            if (res.data.code * 1 === 100) {
              Toast(res.data.msg)
              this.onRefresh()
            } else {
              Toast('错误')
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    tcdl () {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      this.$router.push('/login')
    },
    saveBinding () {
      this.$router.push('/bindinguservipzh')
    },
    saveBinding1 () {
      this.$router.push('/account')
    },
    hzBinding () {
      this.$router.push('/registuservipzh')
    },
    hzBinding1 () {
      this.$router.push('/hz')
    },
    ftBinding () {
      this.$router.push('/ft')
    },
    setpawtcdl () {
      this.$router.push('/setpwd')
    },
    yjwc () {
      this.canRegust = true
      yjwc().then((res) => {
        if (res.data.code * 1 === 100) {
          this.canRegust = false
          this.onRefresh()
        } else {
          this.canRegust = false
          Toast('错误')
        }
      })
    },
    selectBindingUser () {
      const that = this
      this.pageNum = this.pageNum + 1
      var sendData = {
        pageSize: 10,
        pageNum: this.pageNum
      }
      selectBindingUser(sendData).then((res) => {
        if (res.data.code * 1 === 100) {
          this.total = res.data.total
          this.list = [...this.list, ...res.data.data]
          this.loading = false
          if (that.total <= that.list.length) {
            this.finished = true
          } else {
            this.finished = false
          }
        } else if (res.data.code * 1 === 503) {
          this.loading = false
          Toast('错误')
          this.$router.push('/login')
        } else {
          this.loading = false
          Toast('错误')
          // Toast(res.data.msg)
        }
      })
    },
    onLoad () {
      // setTimeout(() => {
      if (this.refreshing) {
        this.list = []
        this.refreshing = false
      }

      // for (let i = 0; i < 10; i++) {
      //   this.list.push(this.list.length + 1)
      // }
      this.selectBindingUser()
      // this.loading = false

      // if (this.list.length >= 40) {
      //   this.finished = true
      // }
      // }, 1000)
    },
    onRefresh () {
      // 清空列表数据
      this.finished = false
      this.refreshing = true
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.pageNum = 0
      this.onLoad()
    },
    editChange (event) {
      console.log('e', event)
    },
    downLoadApp () {
      window.location = `${this.downSrc}`
    },
    bindqd (event) {
      let st = ''
      this.province = event[0].name
      this.city = event[1].name
      this.region = event[2].name
      event.forEach((item, index) => {
        st = st + item.name
      })
      this.add = st
      this.showPopup = false
    },
    zsPopup () {
      this.showPopup = true
    },
    close () {
      this.showPopup = false
    },
    handleClick () {
      // 父组件直接可以调用刷新方法
      this.$refs.slideblock.reset()
    }
  },
  computed: {
    telflag () {
      if (this.tel.length !== 11 || this.msg !== '发送验证码') {
        return true
      } else {
        return false
      }
    },
    flag () {
      // 可以使用 正则表达式 ，目前采用的字符串的长度代替了 正则表达式
      if (
        this.tel.length === 11 &&
        this.code !== '' &&
        this.password.length > 5
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.top {
  width 100%
  height 100%
  padding 15px
  box-sizing border-box
  display flex
  justify-content space-between
  align-items center
  font-size 20px
  flex-direction column
  background url('~@/assets/bgimg.png') no-repeat
  background-size cover
}
.itemclass {
  width 300px
  display flex
  color #fff
}
.btnview {
  display flex
  flex-wrap wrap
  width 300px
  border-radius 10px
  background rgba(255,255,255,0.8)
  padding 20px 10px
  margin-top 30px
}
.btnitem {
  width 60px
  margin 20px
  color #000
  font-size 14px
  text-align center
  display flex
  flex-direction column
  align-items center
}
.btnitem img{
  height 32px
  margin-bottom 10px
}
.tipbox {
  text-align left
  width 100%
  padding 5px 15px
  box-sizing border-box
}
.itembox{
  color #fff
  text-align center
  flex 1
}
.text {
  font-size 14px
}
.num {
 font-size 24px
 margin-bottom 10px
 font-weight bold
}
.btnbox{
  display flex
  justify-content space-around
  width 300px
  height 69px
  background #00acec
  color #ffffff
  border-radius 10px
  margin 10px
  align-items center
}
::v-deep  .van-button--large{
  height 50px
}
.tit
  font-size 20px
  font-weight bold
  padding 10px
.van-cell
  background-color rgba(255,255,0,0.0);
.form-view
  display flex
  flex-direction column
  width: 80%
  max-width 400px
  margin 0
  padding 20px 0
  position absolute
  top 10.5rem
  .itemtitle
    padding 16px 0px 0px 16px
.xiazaiview
  display: flex
  flex-direction: row
  justify-content: space-around
  margin-bottom 20px
  .download
    width: 49%
.popup-slide-box
  background #ffffff
  width 315px
  height 400px
  // border-radius 24px
  text-align center
  .popup-title
    font-size 32px
    color #333333
    height 108px
    line-height 108px
    border-bottom 1px solid #efefef
    font-weight bold
  .popup-content
    width 100%

</style>
