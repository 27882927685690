
<template>
  <div class="box">
    <div class="content" style="height: 100%;">
      <div class="top">
        <!-- <div>账号有效期：1220</div> -->
        <!-- <div>已绑账号：{{datatotal}}个</div>
        <div><van-button type="danger" size="small" @click='tcdl'>退出</van-button></div> -->
      <!-- <div class="tipbox">账号有效期：1220</div> -->
      <!-- <div class="btnbox"> -->
        <!-- <div>已绑账号：33个</div> -->
        <!-- <van-button icon="plus" type="primary" size="small" @click="saveBinding">添加账号</van-button> -->
        <!-- <van-button size="small" @click="yjwc"  :loading="canRegust" :disabled="canRegust" type="info" loading-type="spinner" loading-text="启动中...">
          一键完成任务
        </van-button> -->
      <!-- </div> -->
      <van-tabs @click="onClick"  v-model="active" style="width: 100%; height: 100%;">
        <van-tab title="未完成">
        </van-tab>
        <van-tab title="已完成">
        </van-tab>
        <div style="width: 100%; padding: 0 15px; box-sizing: border-box;height: 700px; overflow-y: auto; margin-bottom: 50px; background: rgba(255, 255, 255, 1);
          overflow-y: auto;">
              <van-cell-group>
                <van-cell title="账号" value="状态" />
              </van-cell-group>
              <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          @load="onLoad"
        >
          <van-cell v-for="(item) in list" :key="item.userPhone" :title="item.userPhone">
            <template #right-icon>
              <span style="color: #999; margin-right: 5px;">悦享卡:{{ item.userAmount?item.userAmount:'0' }}</span>
              <span style="color: red; " v-show="item.taskStatus === '1201'">未完成</span>
              <span style="color: green;" v-show="item.taskStatus === '1202'">已完成</span>
              <van-button plain type="danger" size="mini" @click="unbind(item.userId, item.userPhone)" style="margin-left: 10px;">解绑</van-button>
        </template>
          </van-cell>
        </van-list>
      </van-pull-refresh>
            </div>
            <div style=" text-align: center; position: fixed; bottom: 20px; left: 50%; margin-left: -45px;" v-show="active === 0">
              <van-button size="small" @click="yjwc"  :loading="canRegust" :disabled="canRegust" type="info" loading-type="spinner" loading-text="启动中...">
                一键完成任务
              </van-button>
            </div>
      </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Form, Field, Button, Toast, Dialog, Image as VanImage, Popup, Area, Col, Row, Cell, CellGroup, List, PullRefresh, Tab, Tabs } from 'vant'
import SlideVerify from 'vue-monoplasty-slide-verify'
import { Base64 } from 'js-base64'
import { areaList } from '@vant/area-data'
// import AESUtils from '../../utils/aes.js'
import { selectBindingUser, yjwc, cancelBinding } from '../../api/index.js'
// import axios from 'axios'
Vue.use(Base64)
Vue.use(Col)
Vue.use(Row)
Vue.use(SlideVerify)
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(Toast)
Vue.use(Dialog)
Vue.use(VanImage)
Vue.use(Popup)
Vue.use(Area)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Tab)
Vue.use(Tabs)
export default {
  mounted () {
  },
  created () {
    if (!localStorage.getItem('token') || localStorage.getItem('token') === null) {
      this.$router.push('/login')
    }
    this.alldata()
    // this.selectBindingUser()
    // var ua = navigator.userAgent.toLowerCase()
    // var isWeixin = ua.indexOf('micromessenger') !== -1
    // if (!isWeixin) {
    //   console.log('liulanqi')
    //   this.show = false
    // } else {
    //   console.log('weixin')
    //   this.show = true
    // }
    // this.xzdz()
  },
  data () {
    return {
      active: 0,
      lang: false,
      canRegust: false,
      tel: '',
      dztext: '110101',
      add: '',
      password: '',
      xxdz: '',
      areaList: areaList,
      password1: '',
      code: '', // 用户输入的验证码
      address: '', // 后端传递过来的验证码
      msg: '发送验证码',
      timer: null,
      time: 120,
      showArea: false,
      recomandid: '',
      showPopup: false,
      province: '',
      city: '',
      region: '',
      total: 0,
      datatotal: 0,
      // msg: "",
      text: '向右滑',
      // 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
      accuracy: 3,
      show: false,
      downSrc: '',
      list: [],
      error: false,
      loading: false,
      finished: false,
      refreshing: false,
      pageNum: 0,
      taskStatus: '1201'
      // slideImgs: [''] // 可自定义拼图图片数组，未传则使用插件内默认的图片数组
    }
  },

  methods: {
    onClick (name, title) {
      console.log(name, title)
      if (name === 0) {
        this.taskStatus = '1201'
        this.onRefresh()
      } else if (name === 1) {
        this.taskStatus = '1202'
        this.onRefresh()
      }
    },
    unbind (userId, userPhone) {
      Dialog.confirm({
        title: '提示',
        message: `确定要解绑${userPhone}吗？`
      })
        .then(() => {
          // on confirm
          cancelBinding({ userId: userId }).then((res) => {
            if (res.data.code * 1 === 100) {
              Toast(res.data.msg)
              this.onRefresh()
              this.alldata()
            } else {
              Toast('错误')
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    tcdl () {
      localStorage.removeItem('token')
      this.$router.push('/login')
    },
    saveBinding () {
      this.$router.push('/account')
    },
    yjwc () {
      this.canRegust = true
      yjwc().then((res) => {
        if (res.data.code * 1 === 100) {
          this.canRegust = false
          this.onRefresh()
        } else {
          this.canRegust = false
          Toast('错误')
        }
      })
    },
    selectBindingUser () {
      const that = this
      this.pageNum = this.pageNum + 1
      var sendData = {
        pageSize: 10,
        pageNum: this.pageNum,
        taskStatus: this.taskStatus
      }
      selectBindingUser(sendData).then((res) => {
        if (res.data.code * 1 === 100) {
          this.total = res.data.total
          this.list = [...this.list, ...res.data.data]
          this.loading = false
          if (that.total <= that.list.length) {
            this.finished = true
          } else {
            this.finished = false
          }
        } else if (res.data.code * 1 === 503) {
          this.loading = false
          Toast('错误')
          this.$router.push('/login')
        } else {
          this.loading = false
          Toast('错误')
          // Toast(res.data.msg)
        }
      })
    },
    alldata () {
      const that = this
      var sendData = {
        pageSize: 10,
        pageNum: 1,
        taskStatus: null
      }
      selectBindingUser(sendData).then((res) => {
        if (res.data.code * 1 === 100) {
          that.datatotal = res.data.total
        }
      })
    },
    onLoad () {
      // setTimeout(() => {
      if (this.refreshing) {
        this.list = []
        this.refreshing = false
      }

      // for (let i = 0; i < 10; i++) {
      //   this.list.push(this.list.length + 1)
      // }
      this.selectBindingUser()
      // this.loading = false

      // if (this.list.length >= 40) {
      //   this.finished = true
      // }
      // }, 1000)
    },
    onRefresh () {
      // 清空列表数据
      this.finished = false
      this.refreshing = true
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.pageNum = 0
      this.onLoad()
    },
    editChange (event) {
      console.log('e', event)
    },
    downLoadApp () {
      window.location = `${this.downSrc}`
    },
    bindqd (event) {
      let st = ''
      this.province = event[0].name
      this.city = event[1].name
      this.region = event[2].name
      event.forEach((item, index) => {
        st = st + item.name
      })
      this.add = st
      this.showPopup = false
    },
    zsPopup () {
      this.showPopup = true
    },
    close () {
      this.showPopup = false
    },
    handleClick () {
      // 父组件直接可以调用刷新方法
      this.$refs.slideblock.reset()
    }
  },
  computed: {
    telflag () {
      if (this.tel.length !== 11 || this.msg !== '发送验证码') {
        return true
      } else {
        return false
      }
    },
    flag () {
      // 可以使用 正则表达式 ，目前采用的字符串的长度代替了 正则表达式
      if (
        this.tel.length === 11 &&
        this.code !== '' &&
        this.password.length > 5
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.top {
  width 100%
  height 100%
  padding 15px
  box-sizing border-box
  display flex
  justify-content space-between
  font-size 20px
  background url('~@/assets/bgimg.png') no-repeat
  background-size cover
  padding-top 30px
}
.tipbox {
  text-align left
  width 100%
  padding 5px 15px
  box-sizing border-box
}
.btnbox{
  display flex
  justify-content space-around
  width 100%
  margin 20px 0
  align-items center
}
::v-deep  .van-button--large{
  height 50px
}
.tit
  font-size 20px
  font-weight bold
  padding 10px
.van-cell
  background-color rgba(255,255,0,0.0);
.form-view
  display flex
  flex-direction column
  width: 80%
  max-width 400px
  margin 0
  padding 20px 0
  position absolute
  top 10.5rem
  .itemtitle
    padding 16px 0px 0px 16px
.xiazaiview
  display: flex
  flex-direction: row
  justify-content: space-around
  margin-bottom 20px
  .download
    width: 49%
.popup-slide-box
  background #ffffff
  width 315px
  height 400px
  // border-radius 24px
  text-align center
  .popup-title
    font-size 32px
    color #333333
    height 108px
    line-height 108px
    border-bottom 1px solid #efefef
    font-weight bold
  .popup-content
    width 100%

</style>
