// 封装axios
import axios from 'axios'
const isDev = process.env.NODE_ENV === 'development'
console.log('isDev', isDev)
// 自定义axios
const request = axios.create({
  baseURL: isDev ? '/app' : 'http://47.104.18.179:8085/',
  timeout: 6000000
})
// 设置相关的拦截器
request.interceptors.request.use(function (config) {
  config.headers.token = localStorage.getItem('token')
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})
// 响应的拦截器 - loading效果的消失
request.interceptors.response.use(function (response) {
  return response
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error)
})

export default request
